var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v("POLÍTICA DE COMPLIENCE LEGAL")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid legal-main px-lx-5 px-1" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-lg-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                  border: "none",
                  "border-bottom": "solid #C00000 1.0pt",
                  padding: "0cm 0cm 1.0pt 0cm",
                },
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "margin-top": "18.0pt",
                      "margin-right": "0cm",
                      "margin-bottom": "8.0pt",
                      "margin-left": "21.55pt",
                      "text-align": "justify",
                      "text-indent": "-21.55pt",
                      border: "none",
                      padding: "0cm",
                      "font-size": "24px",
                      "font-family": '"Arial Nova Light",sans-serif',
                      color: "#C00000",
                    },
                  },
                  [_vm._v(" Política de Compliance Penal")]
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  ' Spain Heritage Tours S.L. (en adelante, la "Empresa") es una organización dedicada al turismo patrimonial en España, ofreciendo experiencias culturales únicas a nuestros clientes. Nuestra visión es prestar servicios de manera confiable, segura, sólida, flexible y rentable, anticipándonos al cambio, aprendiendo de la experiencia e innovando permanentemente.'
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Comprometidos con la legalidad y la ética empresarial, definimos los siguientes valores que guían nuestra forma de trabajar y tomar decisiones, siempre teniendo en cuenta aspectos clave en la gestión del cumplimiento legal, específicamente en el ámbito penal:"
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Cumplimiento de la Legislación Penal. Exigimos el estricto cumplimiento de la legislación penal aplicable a la Empresa."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Alineación con los Objetivos Organizacionales. Nuestras acciones estarán siempre alineadas y serán congruentes con los fines de la organización. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Identificación de Riesgos Penales. Identificaremos las actividades en cuyo ámbito puedan ser cometidos delitos que deben ser prevenidos."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Prohibición de Conductas Delictivas. Prohibimos terminantemente la comisión de hechos delictivos en el desempeño de nuestras actividades. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Minimización de Riesgos Penales. Nos comprometemos a minimizar la exposición de la organización a riesgos penales mediante la implementación de controles adecuados."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Para lograr estos objetivos, la Empresa se compromete a desarrollar, implementar, mantener y mejorar continuamente su Sistema de Gestión de Compliance Penal (SGCP), basado en la norma UNE 19601, con el fin de mejorar continuamente la forma en que prestamos nuestros servicios y cumplimos con la legalidad inherente a nuestros clientes y a nuestra organización."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [_vm._v(" Es política de Spain Heritage Tours S.L. que:")]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Se establezcan y revisen anualmente objetivos de compliance penal."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se cumpla con los requisitos del negocio, legales o reglamentarios, las obligaciones contractuales y esta política de compliance penal."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se realicen actividades de formación y concienciación en materia de compliance penal para todo el personal."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se establezcan los medios necesarios para garantizar la continuidad y eficacia del SGCP. "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se desarrolle un proceso de análisis y evaluación de riesgos penales."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se implementen planes de tratamiento y controles correspondientes para mitigar los riesgos detectados."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Se establezca la responsabilidad de los empleados en relación a:"
                ),
                _c("ol", { staticStyle: { "list-style-type": "circle" } }, [
                  _c("li", [
                    _vm._v(
                      "Reportar hechos o conductas sospechosas relativas a riesgos penales a través de los canales de denuncia establecidos."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Preservar la confidencialidad, integridad y disponibilidad de la información, garantizando que el informante no sufra represalias en cumplimiento de la presente política."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Cumplir con las políticas y procedimientos inherentes al Sistema de Gestión de Compliance Penal."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Fomentamos una cultura ética y de cumplimiento que permea todas nuestras actividades: "
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Programas de Formación y Campañas de Concienciación. Diseñados para educar y sensibilizar a todos los niveles de la organización sobre la importancia del compliance penal."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Reconocimiento de Conductas Éticas. Valoramos y reconocemos públicamente las conductas éticas dentro de la Empresa."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Sanciones por Incumplimiento. Imponemos sanciones claras en casos de incumplimiento, reafirmando nuestro compromiso con la legalidad."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Liderazgo de la Alta Dirección. La dirección lidera con el ejemplo, demostrando un compromiso constante con la transparencia, la ética y el cumplimiento normativo."
                ),
              ]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Esta política es de aplicación obligatoria para todas las áreas, departamentos y empleados de Spain Heritage Tours S.L., sin excepción. Su implementación asegura que todas las operaciones, decisiones y procesos se realicen dentro del marco legal vigente, minimizando riesgos penales y protegiendo la reputación corporativa."
                ),
              ]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " Todos los empleados y colaboradores están obligados a:"
                ),
              ]
            ),
            _c("ul", { staticStyle: { "list-style-type": "disc" } }, [
              _c("li", [
                _vm._v(
                  "Conocer y cumplir las políticas y procedimientos del SGCP."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Participar en las actividades de formación y concienciación."
                ),
              ]),
              _c("li", [
                _vm._v(
                  "Reportar cualquier conducta sospechosa o infracción a través de los canales establecidos."
                ),
              ]),
              _c("li", [_vm._v("Revisión y Actualización")]),
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "margin-top": "0cm",
                  "margin-right": "0cm",
                  "margin-bottom": "8.0pt",
                  "margin-left": "0cm",
                  "text-align": "justify",
                  "line-height": "110%",
                  "font-size": "15px",
                  "font-family": '"Arial Nova Light",sans-serif',
                },
              },
              [
                _vm._v(
                  " La política de compliance penal será revisada anualmente o cuando se produzcan cambios significativos en la estructura organizativa, normativa aplicable o en los riesgos identificados, garantizando su adecuación y eficacia continua."
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
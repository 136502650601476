<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain
                    Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">POLÍTICA DE COMPLIENCE LEGAL</p>
            </div>
        </div>
        <div class="container-fluid legal-main px-lx-5 px-1">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">
                        <div
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;border:none;border-bottom:solid #C00000 1.0pt;padding:0cm 0cm 1.0pt 0cm;'>
                            <h1
                                style='margin-top:18.0pt;margin-right:0cm;margin-bottom:8.0pt;margin-left:21.55pt;text-align:justify;text-indent:-21.55pt;border:none;padding:0cm;font-size:24px;font-family:"Arial Nova Light",sans-serif;color:#C00000;'>
                                Pol&iacute;tica de Compliance Penal</h1>
                        </div>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Spain Heritage Tours S.L. (en adelante, la &quot;Empresa&quot;) es una organizaci&oacute;n
                            dedicada al turismo patrimonial en Espa&ntilde;a, ofreciendo experiencias culturales
                            &uacute;nicas a nuestros clientes. Nuestra visi&oacute;n es prestar servicios de manera
                            confiable, segura, s&oacute;lida, flexible y rentable, anticip&aacute;ndonos al cambio,
                            aprendiendo de la experiencia e innovando permanentemente.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Comprometidos con la legalidad y la &eacute;tica empresarial, definimos los siguientes
                            valores que gu&iacute;an nuestra forma de trabajar y tomar decisiones, siempre teniendo en
                            cuenta aspectos clave en la gesti&oacute;n del cumplimiento legal, espec&iacute;ficamente en
                            el &aacute;mbito penal:</p>
                        <ul style="list-style-type: disc;">
                            <li>Cumplimiento de la Legislaci&oacute;n Penal. Exigimos el estricto cumplimiento de la
                                legislaci&oacute;n penal aplicable a la Empresa.</li>
                            <li>Alineaci&oacute;n con los Objetivos Organizacionales. Nuestras acciones estar&aacute;n
                                siempre alineadas y ser&aacute;n congruentes con los fines de la organizaci&oacute;n.
                            </li>
                            <li>Identificaci&oacute;n de Riesgos Penales. Identificaremos las actividades en cuyo
                                &aacute;mbito puedan ser cometidos delitos que deben ser prevenidos.</li>
                            <li>Prohibici&oacute;n de Conductas Delictivas. Prohibimos terminantemente la
                                comisi&oacute;n de hechos delictivos en el desempe&ntilde;o de nuestras actividades.
                            </li>
                            <li>Minimizaci&oacute;n de Riesgos Penales. Nos comprometemos a minimizar la
                                exposici&oacute;n de la organizaci&oacute;n a riesgos penales mediante la
                                implementaci&oacute;n de controles adecuados.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Para lograr estos objetivos, la Empresa se compromete a desarrollar, implementar, mantener y
                            mejorar continuamente su Sistema de Gesti&oacute;n de Compliance Penal (SGCP), basado en la
                            norma UNE 19601, con el fin de mejorar continuamente la forma en que prestamos nuestros
                            servicios y cumplimos con la legalidad inherente a nuestros clientes y a nuestra
                            organizaci&oacute;n.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Es pol&iacute;tica de Spain Heritage Tours S.L. que:</p>
                        <ul style="list-style-type: disc;">
                            <li>Se establezcan y revisen anualmente objetivos de compliance penal.</li>
                            <li>Se cumpla con los requisitos del negocio, legales o reglamentarios, las obligaciones
                                contractuales y esta pol&iacute;tica de compliance penal.</li>
                            <li>Se realicen actividades de formaci&oacute;n y concienciaci&oacute;n en materia de
                                compliance penal para todo el personal.</li>
                            <li>Se establezcan los medios necesarios para garantizar la continuidad y eficacia del SGCP.
                            </li>
                            <li>Se desarrolle un proceso de an&aacute;lisis y evaluaci&oacute;n de riesgos penales.</li>
                            <li>Se implementen planes de tratamiento y controles correspondientes para mitigar los
                                riesgos detectados.</li>
                            <li>Se establezca la responsabilidad de los empleados en relaci&oacute;n a:<ol
                                    style="list-style-type: circle;">
                                    <li>Reportar hechos o conductas sospechosas relativas a riesgos penales a
                                        trav&eacute;s de los canales de denuncia establecidos.</li>
                                    <li>Preservar la confidencialidad, integridad y disponibilidad de la
                                        informaci&oacute;n, garantizando que el informante no sufra represalias en
                                        cumplimiento de la presente pol&iacute;tica.</li>
                                    <li>Cumplir con las pol&iacute;ticas y procedimientos inherentes al Sistema de
                                        Gesti&oacute;n de Compliance Penal.</li>
                                </ol>
                            </li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Fomentamos una cultura &eacute;tica y de cumplimiento que permea todas nuestras actividades:
                        </p>
                        <ul style="list-style-type: disc;">
                            <li>Programas de Formaci&oacute;n y Campa&ntilde;as de Concienciaci&oacute;n.
                                Dise&ntilde;ados para educar y sensibilizar a todos los niveles de la
                                organizaci&oacute;n sobre la importancia del compliance penal.</li>
                            <li>Reconocimiento de Conductas &Eacute;ticas. Valoramos y reconocemos p&uacute;blicamente
                                las conductas &eacute;ticas dentro de la Empresa.</li>
                            <li>Sanciones por Incumplimiento. Imponemos sanciones claras en casos de incumplimiento,
                                reafirmando nuestro compromiso con la legalidad.</li>
                            <li>Liderazgo de la Alta Direcci&oacute;n. La direcci&oacute;n lidera con el ejemplo,
                                demostrando un compromiso constante con la transparencia, la &eacute;tica y el
                                cumplimiento normativo.</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Esta pol&iacute;tica es de aplicaci&oacute;n obligatoria para todas las &aacute;reas,
                            departamentos y empleados de Spain Heritage Tours S.L., sin excepci&oacute;n. Su
                            implementaci&oacute;n asegura que todas las operaciones, decisiones y procesos se realicen
                            dentro del marco legal vigente, minimizando riesgos penales y protegiendo la
                            reputaci&oacute;n corporativa.</p>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            Todos los empleados y colaboradores est&aacute;n obligados a:</p>
                        <ul style="list-style-type: disc;">
                            <li>Conocer y cumplir las pol&iacute;ticas y procedimientos del SGCP.</li>
                            <li>Participar en las actividades de formaci&oacute;n y concienciaci&oacute;n.</li>
                            <li>Reportar cualquier conducta sospechosa o infracci&oacute;n a trav&eacute;s de los
                                canales establecidos.</li>
                            <li>Revisi&oacute;n y Actualizaci&oacute;n</li>
                        </ul>
                        <p
                            style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;line-height:110%;font-size:15px;font-family:"Arial Nova Light",sans-serif;'>
                            La pol&iacute;tica de compliance penal ser&aacute; revisada anualmente o cuando se produzcan
                            cambios significativos en la estructura organizativa, normativa aplicable o en los riesgos
                            identificados, garantizando su adecuaci&oacute;n y eficacia continua.</p>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
export default {
    component: {
        DefaultLayout
    },
    data() {
        return {

        }
    },
    methods: {
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },

}
</script>

<style scoped>
.text-columns {
    column-count: 3;
    /* height: 50vh; */
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.slide-show-container {
    position: relative;
    width: 100%;
    margin: auto;
}

.spainhn-slides {
    display: none;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.legal-main li {
    width: 100%;
    overflow-wrap: break-word;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 10s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 3;
        /* height: 50vh; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }
}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}
</style>